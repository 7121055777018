"use client";
import Link from "next/link";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  formatDateToDDMMYYY,
  generateUID,
  formatNumberWithComma,
} from "@/utils/functions.client";
import {
  useSelectCsrfToken,
  useSelectLocale,
  useSelectGameGroups,
} from "@/hooks";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import { latestGrandDrawEndpoint } from "@/services/client/endpoint";
import { useSelector } from "react-redux";
import sendPastDrawRequest from "@/services/client/sendPastDrawRequest";
import sendPastDrawInfo from "@/services/client/sendPastDrawInfo";
import "@/app/[lang]/styles/style.css";

import "./index.css";

export default function HomeResults({
  currency = "",
  data = {},
  isFullWidth = true,
}) {
  const csrfToken = useSelectCsrfToken();
  const locale = useSelectLocale();
  const gamegroups = useSelectGameGroups();
  const [grandDraw, setGrandDraw] = useState({});
  let resultCombination = [1, 2, 3, 4];
  const resultDigits = [];
  useEffect(() => {
    const getGrandDraw = async () => {
      const response = await sendInternalPostRequest({
        endpoint: latestGrandDrawEndpoint,
        csrfToken,
        data: { gameGroupId: gamegroups?.[0]?.gameGroupID || 0 },
      });
      if (response?.success && !!response?.drawResult?.drawDate) {
        setGrandDraw({ ...response.drawResult });
      }
    };
    if (
      !!csrfToken &&
      Object?.keys(grandDraw)?.length === 0 &&
      Array.isArray(gamegroups) &&
      gamegroups?.length > 0
    )
      getGrandDraw();
  }, [grandDraw, setGrandDraw, csrfToken, gamegroups]);
  if (!!grandDraw?.results) {
    const drawResult = grandDraw?.results.split(",");
    if (
      Array.isArray(drawResult) &&
      drawResult?.length === resultCombination?.length
    ) {
      resultCombination = drawResult;
    }
  }
  if (Array.isArray(resultCombination) && resultCombination?.length > 0) {
    // Sort the array in ascending order
    const sortedResultCombination = resultCombination
      .slice()
      .sort((a, b) => a - b);
    sortedResultCombination.map((digit, index) => {
      resultDigits.push(
        <div key={index + digit} className="hp-navigation__draws--number">
          <span className="digits-iq-bld">{digit}</span>
        </div>
      );
    });
  }
  const lang = useSelector((state) => state.locale.value);
  const [tableResult, setTableResult] = useState({});

  const gameGroupId = gamegroups[0]?.gameGroupID;
  useEffect(() => {
    async function getPastDrawResult() {
      const pastDrawResult = await sendPastDrawRequest(csrfToken, gameGroupId);
      const resultSetWithNumbers = pastDrawResult?.pastDraws?.resultSet?.filter(
        (item) => item?.results && item?.results !== "NotGenerated"
      );
      if (resultSetWithNumbers && resultSetWithNumbers.length > 0) {
        const firstItemDrawID = resultSetWithNumbers[0]?.drawID;
        getPastDrawInfo(csrfToken, firstItemDrawID);
      }
    }
    if (csrfToken && gameGroupId) getPastDrawResult();
  }, [lang, csrfToken, gameGroupId]);
  async function getPastDrawInfo(csrfToken, drawID) {
    const pastDrawInfo = await sendPastDrawInfo(csrfToken, drawID);
    setTableResult(pastDrawInfo?.pastDraws);
  }
  const refflesData = tableResult?.subDraws?.[0]?.gameTiers?.[0]?.prizeTiers;
  const firstWinningsAmount = refflesData?.[0]?.playerTicketNumbers;
  const firstWinner = firstWinningsAmount?.toString().split(",")[0];
  const secondWinner = firstWinningsAmount?.toString().split(",")[1];
  const SecondWinningsAmount = refflesData?.[1]?.playerTicketNumbers;
  const thirdWinner = SecondWinningsAmount?.toString().split(",")[0];
  const fourthWinner = SecondWinningsAmount?.toString().split(",")[1];

  function formatWinningsAmount(amount) {
    if (amount >= 1000000 && amount < 10000000) {
      return numberWithCommas(amount);
    } else if (amount >= 10000000 && amount < 100000000) {
      return numberWithCommas(amount);
    } else if (amount >= 100000 && amount < 1000000) {
      return numberWithCommas(amount);
    } else if (amount >= 1000 && amount < 100000) {
      return numberWithCommas(amount);
    } else if (amount >= 100 && amount < 1000) {
      return numberWithCommas(amount);
    } else if (amount >= 10000000) {
      return numberWithCommas(amount);
    }
    return amount;
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const formatARDate = formatDateToDDMMYYY(
    grandDraw?.drawDate,
    undefined,
    "ar"
  ).split(" ");
  return (
    <div className={isFullWidth ? "col-xl-12 col-lg-12" : "col-xl-8 col-lg-12"}>
      <div className="hp-navigation__box box-latest">
        <h2 className="d-flex justify-content-center">
          <span className="bold-iq">{data?.title || ""} </span>
          <span className="bold-iq px-1">-</span>
          <span className="flex box-latest--date digits-iq-bld">
            {locale === "en" ? (
              formatDateToDDMMYYY(grandDraw?.drawDate || "", undefined, "en-US")
            ) : (
              <>
                {formatARDate[0]}{" "}
                <span className="bold-iq px-1">{formatARDate[1]}</span>{" "}
                {formatARDate[2]}
              </>
            )}
          </span>
        </h2>
        <div className="hp-navigation__inner--container pr-2 pl-2">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xl-6">
              <div className="hp-navigation__box box-results">
                <div className="hp-navigation__box--content">
                  <h3 className="bold-iq">{data?.grandDrawTitle || ""}</h3>
                  <div className="hp-navigation__draws">{resultDigits}</div>
                  <div className="hp-navigation__info">
                    <div className="row">
                      {grandDraw?.prizeTiers?.length > 0 &&
                        grandDraw?.prizeTiers?.map((prizeTier) => {
                          return (
                            <div
                              key={"prizeTier" + generateUID()}
                              className="col-lg-12 mb-3"
                            >
                              <div className="hp-navigation__info--box small">
                                <p className="mtch bold-iq">
                                  <span className="bold-iq">
                                    {data?.matchingText || ""}
                                  </span>

                                  <span className="digits-iq-bld">
                                    {locale === "en"
                                      ? `${prizeTier?.columnsMatch[0] || 0}/${
                                          grandDraw?.prizeTiers?.length || 0
                                        }`
                                      : `${
                                          grandDraw?.prizeTiers?.length || 0
                                        }/${prizeTier?.columnsMatch[0] || 0}`}
                                  </span>
                                </p>
                                <p className="digits-iq-bld">
                                  {formatNumberWithComma(
                                    prizeTier?.totalWinners || 0
                                  )}
                                  <span className="bold-iq">
                                    {data?.winnersText || ""}
                                  </span>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6">
              <div className="hp-navigation__box box-results box-raffle box-raffle-temporary">
                <div className="hp-navigation__box--content flex">
                  <h3 className="bold-iq">{data?.promoTitle || ""}</h3>
                  <div className="hp-navigation__info">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="hp-navigation__info--box">
                          <div className="d-flex flex-column">
                            <span>{data?.promoText}</span>
                            <span className="digits-iq-bld winner-txt">
                              {firstWinner}
                            </span>
                          </div>

                          <p
                            className={`font-semibold hp-navigation__info--price ${
                              locale === "ar" ? "dir-ltr" : ""
                            }`}
                          >
                            {currency}
                            <span className="digits-iq-bld">
                              {formatWinningsAmount(
                                refflesData?.[0]?.winningsAmount
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="hp-navigation__info--box">
                          <div className="d-flex flex-column">
                            <span>{data?.promoText}</span>
                            <span className="digits-iq-bld winner-txt">
                              {secondWinner}
                            </span>
                          </div>
                          <p
                            className={`font-semibold hp-navigation__info--price ${
                              locale === "ar" ? "dir-ltr" : ""
                            }`}
                          >
                            {currency}
                            <span className="digits-iq-bld">
                              {formatWinningsAmount(
                                refflesData?.[0]?.winningsAmount
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="hp-navigation__info--box">
                          <div className="d-flex flex-column">
                            <span>{data?.promoText}</span>
                            <span className="digits-iq-bld winner-txt">
                              {thirdWinner}
                            </span>
                          </div>
                          <p
                            className={`font-semibold hp-navigation__info--price ${
                              locale === "ar" ? "dir-ltr" : ""
                            }`}
                          >
                            {currency}
                            <span className="digits-iq-bld">
                              {formatWinningsAmount(
                                refflesData?.[1]?.winningsAmount
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="hp-navigation__info--box">
                          <div className="d-flex flex-column">
                            <span>{data?.promoText}</span>
                            <span className="digits-iq-bld winner-txt">
                              {fourthWinner}
                            </span>
                          </div>
                          <p
                            className={`font-semibold hp-navigation__info--price ${
                              locale === "ar" ? "dir-ltr" : ""
                            }`}
                          >
                            {currency}
                            <span className="digits-iq-bld">
                              {formatWinningsAmount(
                                refflesData?.[1]?.winningsAmount
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hp-navigation__box--btn">
                    <Link
                      className="btn btn--dark bold-iq"
                      href={data.linkUrl || ""}
                    >
                      {data?.linkText || ""}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Link href={data.linkUrl || ""}></Link>
    </div>
  );
}

HomeResults.propTypes = {
  /**
   * Data
   */
  data: PropTypes.object.isRequired,
  /**
   * Section width size
   */
  isFullWidth: PropTypes.bool,
};
