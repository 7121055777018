"use client";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useSelectLocale,
  useSelectGameGroups,
  useSelectCsrfToken,
} from "@/hooks";
import getCurrentDraw from "@/services/client/getCurrentDraw";
import { checkNumberAndWrap, formatNumberWithComma } from "@/utils/functions.client";
import parser from "html-react-parser";
import "@/app/[lang]/styles/style.css";

export default function HomePrizes({ topPrize = {}, promoPrize = {} }) {
  const locale = useSelectLocale();
  const gamegroups = useSelectGameGroups();
  const csrfToken = useSelectCsrfToken();
  const gameGroupId = gamegroups[0]?.gameGroupID;
  const gameGroupRaffleId = gamegroups[0]?.subGames[0]?.gameGroupID;
  //handle total & raffleprize
  const [totalPrize, setTotalPrize] = useState();
  const [rafflePrize, setRafflePrize] = useState();
  useEffect(() => {
    const getCurrentDraws = async (csrfToken) => {
      const response = await getCurrentDraw(csrfToken);
      // totalPrize
      const filterTotalPrize = response?.draws?.filter(function (el) {
        return el.gameGroupID === gameGroupId;
      });
      // calculate totalPrize
      const jackpotTier = filterTotalPrize[0]?.gameTiers[0]?.prizeTiers?.find(
        (tier) => tier.tier === "Jackpot"
      );
      const jackpotAmount = formatNumberWithComma(jackpotTier?.amount);
      setTotalPrize(jackpotAmount);
      //rafflePrize
      const filterRafflePrize = response?.draws?.filter(function (el) {
        return el.gameGroupID === gameGroupRaffleId;
      });
      // calculate RafflePrize
      const getRaffelPrize =
        filterRafflePrize[0]?.gameTiers[0]?.prizeTiers?.reduce(
          (total, tier) => total + tier.amount * tier.numWinners,
          0
        );
      setRafflePrize(formatNumberWithComma(getRaffelPrize));
    };
    getCurrentDraws();
  }, [gameGroupId, gameGroupRaffleId]);

  return (
    <div>
      <div className="countdown__timer_wrapper ">
        <div className="countdown__pricebubble">
          <div className="countdown__pricebubble--content">
            <p className="bold-iq text-brown">{topPrize?.title || ""}</p>
            <h3 className="">
              {locale === "en"
                ? `${topPrize?.currency || ""} ${totalPrize || ""}`
                : parser(`<span class="digits-iq !text-[32px]">${totalPrize || ""}</span> ${topPrize?.currency || ""}`)}
            </h3>
          </div>
        </div>
      </div>
      <div className="countdown__timer_wrapper">
        <div className="countdown__pricebubble">
          <div className="countdown__pricebubble--content">
            <p className="bold-iq text-brown">{promoPrize?.title || ""}</p>
            <h3 className="">
              {locale === "en"
                ? `${promoPrize?.currency || ""} ${ rafflePrize || ""}`
                : parser(`<span class="digits-iq !text-[32px]" >${rafflePrize || ""}</span> ${promoPrize?.currency || ""}`)}
            </h3>
          </div>
        </div>
      </div>

      <p className="text-white text-center terms">{promoPrize?.terms}</p>
    </div>
  );
}

HomePrizes.propTypes = {
  /**
   * Top Prize info
   */
  topPrize: PropTypes.object.isRequired,
  /**
   * Top Prize info
   */
  promoPrize: PropTypes.object.isRequired,
};
