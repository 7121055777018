"use client";
import axios from "axios";
import PropTypes from "prop-types";

export default async function sendPastDrawRequest(csrfToken, gameGroupId) {
  try {
    const response = await axios.post(
      "/api/pastDraws",
      { gameGroupId },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken || "",
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      throw new Error("Failed to get past draws request due to server error.");
    }
    return error.response ? error.response.data : { error: "Unknown error occurred" };
  }
}

sendPastDrawRequest.propTypes = {
  /**
   * CSRF Token
   */
  csrfToken: PropTypes.string.isRequired,
  /**
   * Game group ID
   */
  gameGroupId: PropTypes.number,
};
