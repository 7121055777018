"use client";
import PropTypes from "prop-types";

export default function HomeTimer({ countDownTimer = {}, timeLeft }) {
  return (
    <div className="countdown homepage">
      <div className="countdown__timer">
        <div className="countdown__timer--wrapper wrapper-ss">
          <div className="base-timer">
            <div className="countdown__timer--boxes-row">
              <div className="countdown__timer--valuewrapper d-flex align-items-center border-bottom">
                <span
                  className="countdown__timer--value base-timer-label-days digits-iq-bld
"
                >
                  {timeLeft.days}
                </span>
              </div>
              <p className="bold-iq">{countDownTimer?.daysLabel || ""}</p>
            </div>
          </div>
          <div className="base-timer">
            <div className="countdown__timer--boxes-row">
              <div className="countdown__timer--valuewrapper d-flex align-items-center border-bottom">
                <span className="countdown__timer--value base-timer-label-hours digits-iq-bld">
                  {timeLeft.hours}
                </span>
              </div>
              <p className="bold-iq">{countDownTimer?.hoursLabel || ""}</p>
            </div>
          </div>
          <div className="base-timer">
            <div className="countdown__timer--boxes-row">
              <div className="countdown__timer--valuewrapper d-flex align-items-center border-bottom">
                <span className="countdown__timer--value base-timer-label-minutes digits-iq-bld">
                  {timeLeft.minutes}
                </span>
              </div>
              <p className="bold-iq">{countDownTimer?.minutesLabel || ""}</p>
            </div>
          </div>
          <div
            className="base-timer base-timer-seconds"
            data-hard-limit="60"
            data-time-left="10"
            data-time-passed="50"
            data-total-time-passed="350"
          >
            <div className="countdown__timer--boxes-row">
              <div className="countdown__timer--valuewrapper d-flex align-items-center border-bottom">
                <span className="countdown__timer--value base-timer-label-seconds digits-iq-bld">
                  {timeLeft.seconds}
                </span>
              </div>
              <p className="bold-iq">{countDownTimer?.secondsLabel || ""}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

HomeTimer.propTypes = {
  /**
   * Countdown timer label
   */
  countDownTimer: PropTypes.object.isRequired,
};
