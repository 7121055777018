"use client";
import Image from "next/image";
import PropTypes from "prop-types";

export default function HomeDownload({
    desktopImage = "",
    mobileImage = "",
    appStoreUrl = "",
    googlePlayUrl = "",
}) {
    return (
        <section className="downloadbanner">
            <div className="container">
                <div className="downloadbanner__inner">
                    <div className="downloadbanner__img desktop">
                        <Image
                            src={desktopImage}
                            alt="Download"
                            style={{ width: "auto", height: "auto" }}
                            width={930}
                            height={262}
                            loading="lazy"
                        />
                    </div>
                    <div className="downloadbanner__img mobile">
                        <Image
                            src={mobileImage}
                            alt="Download"
                            style={{ width: "auto", height: "auto" }}
                            width={525}
                            height={607}
                            loading="lazy"
                        />
                    </div>
                    <div className="downloadbanner__buttons">
                        <a
                            target="blank"
                            href={appStoreUrl || ""}
                            className="Apple"
                        ></a>
                        <a
                            target="blank"
                            href={googlePlayUrl || ""}
                            className="android"
                        ></a>
                    </div>
                </div>
            </div>
        </section>
    );
}

HomeDownload.propTypes = {
    /**
     * Image for desktop
     */
    desktopImage: PropTypes.string.isRequired,
    /**
     * Image for desktop
     */
    mobileImage: PropTypes.string.isRequired,
    /**
     * App store Url
     */
    appStoreUrl: PropTypes.string.isRequired,
    /**
     * Google play Url
     */
    googlePlayUrl: PropTypes.string.isRequired,
};
