"use client";
import PropTypes from "prop-types";
import parser from "html-react-parser";
import {
  useSelectCsrfToken,
  
  useSelectLocale,
  useSelectReadyStore,
  useSelectGameGroups,
} from "@/hooks";
import { useState, useEffect } from "react";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import { formatNumberWithComma } from "@/utils/functions.client";

export default function HomeWinnersMobile({ label = "" }) {
  const readyStore = useSelectReadyStore();
  const csrfToken = useSelectCsrfToken();

  const [totalWinners, setTotalWinners] = useState(0);
  const locale = useSelectLocale();
  const gameGroups = useSelectGameGroups();
  const gameId = gameGroups[0]?.gameID;
  const currency = gameGroups?.[0]?.gamePrice?.currencyCode;
  useEffect(() => {
    async function getTotalWinners() {
      const result = await sendInternalPostRequest({
        endpoint: "/api/total-winners",
        data: { currencyCode: currency, gameId },
        csrfToken,
      });
      const total = parseInt(result?.totalWinners || 0) || 0;
      if (!isNaN(total) && total > 0) setTotalWinners(total);
    }

    if (readyStore && !!csrfToken && !!currency) getTotalWinners();
  }, [readyStore, csrfToken, currency, setTotalWinners, gameId]);

  return (
    <div className="col-lg-12">
      <div className="hp-navigation__box box-total mobile">
        
        <div
          style={{
            borderRight: locale == "en" ? "1px solid white" : "",
            borderLeft: locale == "ar" ? "1px solid white" : "",
            flex:"1"
          }}
        >
          {parser(label || "")}
         
        </div>
        <div className="hp-navigation__box--price item3">
          <h3 className="digits-iq-bld mb-0">
            {formatNumberWithComma(totalWinners || "")}
          </h3>
        </div>
      </div>
    </div>
  );
}

HomeWinnersMobile.propTypes = {
  /**
   * Data
   */
  label: PropTypes.string.isRequired,
};
