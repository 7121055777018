"use client";
import Link from "next/link";
import PropTypes from "prop-types";
import parser from "html-react-parser";
import { useSelector } from "react-redux";
import { trackClickEvent } from "@/utils/gtm";

export default function HomeStories({ data = null }) {
  const gtmData = useSelector((state) => state?.authUser?.data);
  const handleClick = (action, label) => {
    trackClickEvent(
      "e_bodyinteraction",
      "non_ecommerce",
      action,
      label,
      gtmData?.lang || "en",
      gtmData?.currentPage || "",
      gtmData?.category || "",
      gtmData?.subcategory || "",
      gtmData?.previousPage || "",
      gtmData?.loggedIn || "",
      gtmData?.userid || ""
    );
  };
  return (
    <div className="col-xl-4 col-lg-12">
      <div
        className="hp-navigation__box box-stories"
        style={{
          background: "#fb7a74",
          padding: "15px 0px",
          marginTop: "15px",
        }}
      >
        <div className="hp-navigation__box--content ">
          <div className="hp-navigation__box--heading">
            {parser(data?.label || "")}
          </div>
          <div className="hp-navigation__box--video">
            <div
              className="absolute inset-0 opacity-50"
              style={{ pointerEvents: "none" }}
              onClick={() => handleClick("video_start", "home-stories")}
            ></div>
            <div id="milvid">
              <iframe
                width="100%"
                height="210"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen={true}
                src={data.video || ""}
                loading="lazy"
                data-gtm-yt-inspected-18="true"
                id="971300767"
                title="home-stories"
              ></iframe>
            </div>
          </div>
          <div
            className="hp-navigation__box--btn"
            onClick={() => handleClick("more_stories", "")}
          >
            <Link className="btn btn--dark bold-iq" href={data.linkUrl}>
              {data.linkText || ""}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

HomeStories.propTypes = {
  /**
   * Data
   */
  data: PropTypes.object.isRequired,
};
