"use client";
import axios from "axios";
import PropTypes from "prop-types";

export default async function getCurrentDraw(
    csrfToken
) {
    const response = await axios
        .get(
            "api/draws/current",
            {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": csrfToken || "",
                },
            }
        )
        .then((res) => res.data)
        .catch((error) => {
            if (error && error?.response && error?.response?.status >= 500) {
                throw new Error("Failed to get current draws request.");
            }
            return error.response.data;
        });

    return response;
}

getCurrentDraw.propTypes = {
    /**
     * CSRF Token
     */
    csrfToken: PropTypes.string.isRequired,
    /**
     * Game group ID
     */
    gameGroupId: PropTypes.number,
};
