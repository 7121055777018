"use client";
import PropTypes from "prop-types";
import Link from "next/link";
import HomeTimer from "./timer";
import HomePrizes from "./prizes";
import HomeWinners from "./winners";
import {
  useSelectCsrfToken,
  useSelectLocale,
  useSelectReadyStore,
  useSelectGameGroups,
} from "@/hooks";
import { useEffect, useState } from "react";
import sendCurrentDrawsRequest from "@/services/client/sendCurrentDrawsRequest";
import {
  formatWeekDay,
  formatDateString,
  calculateTimeLeft,
  formatDay,
  formatMonth,
  formatYear,
} from "@/utils/functions.client.js";
import "@/app/[lang]/styles/style.css";
import { DIGITS } from "@/utils/constants";

export default function HomeCountdown({
  countDownTimer = {},
  topPrize = {},
  promoPrize = {},
  ctaPrizes = {},
  totalWinnersLabel = "",
}) {
  const readyStore = useSelectReadyStore();
  const csrfToken = useSelectCsrfToken();
  const gamegroups = useSelectGameGroups();

  const [formattedDate, setFormattedDate] = useState("");
  const locale = useSelectLocale();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    async function getCurrentDraws() {
      const response = await sendCurrentDrawsRequest(
        csrfToken || "",
        gamegroups?.[0]?.gameGroupID || 0
      );

      if (response && response?.success && response?.draws?.[0]?.salesEnd) {
        const newDate = response?.draws?.[0]?.salesEnd || "";

        if (newDate) setFormattedDate(newDate);
      }
    }
    function getDrawDate() {
      if (
        readyStore &&
        csrfToken &&
        Array.isArray(gamegroups) &&
        gamegroups?.length > 0
      )
        getCurrentDraws();
    }
    getDrawDate();
    const getDateInterval = setInterval(getDrawDate, DIGITS.THREE_LAKH);

    return () => clearInterval(getDateInterval);
  }, [csrfToken, readyStore, gamegroups]);

  useEffect(() => {
    if (formattedDate) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(formattedDate));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [formattedDate]);

  return (
    <div className="col-lg-6">
      <div className="col-lg-12">
        <div
          className="bg-white d-flex justify-content-center"
          style={{
            borderTopLeftRadius: "14px",
            borderTopRightRadius: "14px",
            padding: "15px 0px",
            border: "1px solid #fb9a63",
            borderBottom: "none",
          }}
        >
          <div className="countdown hp date">
            {formattedDate ? (
              <p className={ `!text-brown ${locale === "en" ? "digits-iq-bld" : "bold-iq"}`}>
                {formatWeekDay(formattedDate, locale)}
                <span className="!text-brown digits-iq-bld">
                  {formatDay(formattedDate, "en")}
                </span>
                <span className={ `!text-brown ${locale === "en" ? "digits-iq-bld" : "bold-iq"}`}>
                  {formatMonth(formattedDate, locale)}
                </span>
                <span className="!text-brown digits-iq-bld">
                  {formatYear(formattedDate, "en")}
                </span>
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="hp-navigation__box box-draw-info">
          <div className="hp-navigation__box--content">
            <div className="hp-navigation__box--wrapper">
              <HomeTimer countDownTimer={countDownTimer} timeLeft={timeLeft} />
              <HomePrizes topPrize={topPrize} promoPrize={promoPrize} />
            </div>
            <div className="hp-navigation__box--btn">
              <Link
                className="btn btn--dark bold-iq"
                href={ctaPrizes.link || ""}
              >
                {ctaPrizes.text || ""}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <HomeWinners label={totalWinnersLabel || ""} />
    </div>
  );
}

HomeCountdown.propTypes = {
  /**
   * Countdown timer label
   */
  countDownTimer: PropTypes.object.isRequired,
  /**
   * Top Prize info
   */
  topPrize: PropTypes.object.isRequired,
  /**
   * Top Prize info
   */
  promoPrize: PropTypes.object.isRequired,
  /**
   * CTA Prizes
   */
  ctaPrizes: PropTypes.object.isRequired,
  /**
   * Total Winners label
   */
  totalWinnersLabel: PropTypes.string.isRequired,
};
