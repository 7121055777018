"use client";
import axios from "axios";
import PropTypes from "prop-types";

export default async function sendPastDrawInfo(csrfToken, drawID) {
  try {
    const response = await axios.post(
      "/api/pastDrawInfo",
      { drawID },
      {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken || "",
        },
      }
    );
    if (response?.status >= 500) {
      throw new Error("Failed to get current draws request.");
    }
    return response.data;
  } catch (error) {
    // return error.response?.data;
    return error;
  } 
}

sendPastDrawInfo.propTypes = {
  /**
   * CSRF Token
   */
  csrfToken: PropTypes.string.isRequired,
  /**
   * Game group ID
   */
  drawID: PropTypes.string,
};
